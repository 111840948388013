/* Let CRA handle linting for sample app */
import React, { Component } from 'react';
import classNames from 'classnames';
// eslint-disable-next-line
import OT  from '@opentok/client';

import AccCore from 'opentok-accelerator-core';
import 'opentok-solutions-css';


import './App.css';



let otCore;
const otCoreOptions = {
  credentials: {
    apiKey: window.openTok.apiKey,
    sessionId: window.openTok.sessionId,
    token: window.openTok.token
  },
  // A container can either be a query selector or an HTML Element
  streamContainers(pubSub, type, data, stream) {
    return {
      publisher: {
        camera: '#cameraPublisherContainer',
        screen: '#screenPublisherContainer',
      },
      subscriber: {
        camera:  '#cameraSubscriberContainer',
        screen: '#screenSubscriberContainer',
      },
    }[pubSub][type];
  },
  controlsContainer: '#controls',
  packages: ['textChat', 'screenSharing', 'annotation'],
  communication: {
    callProperties: null, // Using default
  },
  textChat: {
    name: '', // eslint-disable-line no-bitwise
    waitingMessage: 'Messages will be delivered when other users arrive',
    container: '#chat',
  },
  screenSharing: {
    extensionID: 'plocfffmbcclpdifaikiikgplfnepkpo',
    annotation: true,
    externalWindow: false,
    dev: true,
    screenProperties: {
      insertMode: 'append',
      width: '100%',
      height: '100%',
      showControls: false,
      style: {
        buttonDisplayMode: 'off',
      },
      videoSource: 'window',
      fitMode: 'contain' // Using default
    },
  },
  annotation: {
    absoluteParent: {
      publisher: '.App-video-container',
      subscriber: '.App-video-container'
    }
  },
};

/**
 * Build classes for container elements based on state
 * @param {Object} state
 */
const containerClasses = (state) => {
  const { active, meta, localAudioEnabled, localVideoEnabled } = state;
  const sharingScreen = meta ? !!meta.publisher.screen : false;
  const viewingSharedScreen = meta ? meta.subscriber.screen : false;
  const activeCameraSubscribers = meta ? meta.subscriber.camera : 0;
  const activeCameraSubscribersGt2 = activeCameraSubscribers > 2;
  const activeCameraSubscribersOdd = activeCameraSubscribers % 2;
  const screenshareActive = viewingSharedScreen || sharingScreen;
  return {
    controlClass: classNames('App-control-container', 'flex-wrap', { "d-none": !active }),
    localAudioClass: classNames('ots-video-control circle audio', { "d-none": !active, muted: !localAudioEnabled }),
    localVideoClass: classNames('ots-video-control circle video', { "d-none": !active, muted: !localVideoEnabled }),
    localCallClass: classNames('ots-video-control circle end-call', { "d-none": !active }),
    cameraPublisherClass: classNames('video-container', { "d-none": !active, small: !!activeCameraSubscribers || screenshareActive, left: screenshareActive }),
    screenPublisherClass: classNames('video-container', { "d-none": !active || !sharingScreen }),
    cameraSubscriberClass: classNames('video-container', { "d-none": !active || !activeCameraSubscribers },
      { 'active-gt2': activeCameraSubscribersGt2 && !screenshareActive },
      { 'active-odd': activeCameraSubscribersOdd && !screenshareActive },
      { small: screenshareActive }
    ),
    screenSubscriberClass: classNames('video-container', { "d-none": !viewingSharedScreen || !active }),
  };
};

const connectingMask = () =>
  <div className="App-mask">
    <div>Loading...</div><br />
    <div className="message with-spinner">Connecting</div>
  </div>;

const startCallMask = (start, clickstart) =>
  <div className="App-mask">
    <div className={classNames({"d-none": clickstart})}>
      <button className="message button clickable" onDoubleClick={()=>{console.log('dbclick')}} onClick={start}>Accedi alla stanza </button>
    </div>

  </div>;

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      clickstart: false,
      connected: false,
      count_connected:0,
      active: false,
      publishers: null,
      subscribers: null,
      meta: null,
      localAudioEnabled: true,
      localVideoEnabled: true,
    };
    this.startCall = this.startCall.bind(this);
    this.endCall = this.endCall.bind(this);
    this.toggleLocalAudio = this.toggleLocalAudio.bind(this);
    this.toggleLocalVideo = this.toggleLocalVideo.bind(this);
  }

  componentDidMount() {
    otCore = new AccCore(otCoreOptions);
    otCore.connect().then((cc) => {
        this.setState({ connected: true })
        // if(cc.connections > 2 && !window.hoster){
        //   var url = new URL(window.location.href);
        //   window.location.href= url.searchParams.get("redirect") || '/'
        // }

    });
    const events = [
      'subscribeToCamera',
      'unsubscribeFromCamera',
      'subscribeToScreen',
      'unsubscribeFromScreen',
      'startScreenShare',
      'endScreenShare',
    ];

    events.forEach(event => otCore.on(event, ({ publishers, subscribers, meta }) => {
      console.log(event)
      if(event==='unsubscribeFromCamera'){
        this.redirectIfNotHost(subscribers)
      }
      this.setState({ publishers, subscribers, meta });
    }));
  }

  redirectIfNotHost(subscribers) {
    if(window.hoster) return
    var url = new URL(window.location.href);

    let users_conn = (Object.values(subscribers.camera)||[]).map(({stream})=>JSON.parse(stream.connection.data||'[]'))

  //   if(users_conn.length!==1 || !((users_conn[0]||{}).hoster))
  //     window.location.href= url.searchParams.get("redirect") || '/'
  }

  startCall() {
    this.setState({clickstart: true})
    otCore.startCall()
      .then(({ publishers, subscribers, meta }) => {

        this.redirectIfNotHost(subscribers)

        this.setState({ publishers, subscribers, meta, active: true });
      }).catch(error => console.log(error));
  }

  endCall() {
    otCore.endCall();
    this.setState({ active: false, clickstart: false });
  }

  toggleLocalAudio() {
    otCore.toggleLocalAudio(!this.state.localAudioEnabled);
    this.setState({ localAudioEnabled: !this.state.localAudioEnabled });
  }

  toggleLocalVideo() {
    otCore.toggleLocalVideo(!this.state.localVideoEnabled);
    this.setState({ localVideoEnabled: !this.state.localVideoEnabled });
  }

  render() {
    const { connected, active, count_connected } = this.state;
    const {
      localAudioClass,
      localVideoClass,
      localCallClass,
      controlClass,
      cameraPublisherClass,
      screenPublisherClass,
      cameraSubscriberClass,
      screenSubscriberClass,
    } = containerClasses(this.state);

    return (
      <div className="App">
        <div className="App-main row">

          <div className={"col-md-12"}>
            <div className="App-video-container">
              { !connected && connectingMask() }
              { connected && !active && startCallMask(this.startCall, this.state.clickstart) }

              <div id="cameraPublisherContainer" className={cameraPublisherClass} />
              <div id="screenPublisherContainer" className={screenPublisherClass} />
              <div id="cameraSubscriberContainer" className={cameraSubscriberClass} />
              <div id="screenSubscriberContainer" className={screenSubscriberClass} />

            </div>
            <div className={'controller-container'+(!active?"d-none":"")} >
              <div id="controls" className={controlClass}>
                  <div className={localAudioClass} onClick={this.toggleLocalAudio} />
                  <div className={localVideoClass} onClick={this.toggleLocalVideo} />
                  <div className={localCallClass} onClick={this.endCall} />
              </div>
            </div>
          </div>

          <div id="chat" className="App-chat-container" />
        </div>
      </div>
    );
  }
}

export default App;
